import styled, { css } from 'styled-components';
import {
  color,
  mq,
  text_xs,
  hideOnPrint,
  showOnPrint,
  svg,
  depth,
  button,
  safeFocusRemoval,
  heading_s
} from '_utils/styles';
import { spacing } from '_utils/props';
import { Link } from 'react-router-dom';

// INFO: Wrapper Content
export const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${color('all.white')};
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.header.height.xs}px;
  ${({ theme }) => spacing({ theme, p: 3 })}

  ${mq('md')} {
    height: ${({ theme }) => theme.header.height.md}px;
  }

  & .header-hidden {
    visibility: hidden;
  }
`;

export const HiddenWrapper = styled.div`
  height: 100%;
  display: flex;
  align-self: center;
`;

export const HomeButton = styled(Link)`
  height: 40px;
  ${mq('md')} {
    display: none;
  }

  ${hideOnPrint}
`;

export const HomeButtonLogo = styled.span`
  ${svg(39, 40)}
  width: 39px;
  flex: none;
  path {
    fill: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const HomeButtonTitle = styled(Link)`
  display: none;
  height: 40px;

  ${mq('md')} {
    display: inline-block;
  }
  ${showOnPrint}
`;

export const HomeButtonTitleLogo = styled.span`
  ${svg(249, 40)}
  width: 249px;
  flex: none;
  path {
    fill: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const SearchBar = styled.div`
  flex: 1 1 auto;
  height: 40px;
  ${({ theme }) => spacing({ theme, mx: 3 })}
  ${hideOnPrint}
`;

export const SkipContainer = styled.div`
  height: 0;
  background-color: ${({ theme }) => theme.colors.background.senary};
  &.skipActive {
    height: 32px;
  }
  transition: all 0.1s linear;
`;

export const SkipLink = styled.a`
  opacity: 0;
  color: inherit;
  ${text_xs}
  ${({ theme }) => spacing({ theme, px: 1 })}
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.element.focused};
  }

  line-height: 30px !important;
  margin: 0;
  display: inline-block;

  .skipActive & {
    opacity: 1;
  }
`;

export const LoginRegisterButton = styled.a`
  ${button('default', 'outline')};
  ${safeFocusRemoval};
  ${heading_s};
  ${hideOnPrint}
  ${({ theme }) => spacing({ theme, mr: 2, px: 2 })};
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;

  ${mq.lessThan('md')} {
    ${({ theme }) => spacing({ theme, mr: '6px' })};
  }

  & svg {
    fill: ${({ theme }) => theme.colors.button.outline.color};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.button.outline.colorHover};
    }
  }

  body:not(.safe-focus-removal) &:focus {
    &:before {
      content: none;
    }
  }

  body:not(.safe-focus-removal) &:focus-visible {
    &:before {
      position: absolute;
      left: -6px;
      top: -6px;
      content: '';
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border: 2px solid ${({ theme }) => theme.colors.element.focused};
      border-radius: 9999px;
    }
  }
  font-family: 'Helvetica Now Text', Helvetica, sans-serif;
  font-weight: 700;
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;
export const UserNameLabel = styled.span`
  margin-left: 10px;
`;

const calcMenuOffset = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xs', extra: number = 0) => ({
  theme,
  beforeRootSpace
}) => {
  const offset = theme.header.height[bp] + (beforeRootSpace || 0) + extra;

  return css`
    top: ${offset}px;
    height: calc(100% - ${offset}px);
  `;
};

export const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
  overflow: hidden;

  ${calcMenuOffset()}

  ${mq('md')} {
    ${calcMenuOffset('md')}
  }

  &.skipActive {
    ${calcMenuOffset('xs', 32)}

    ${mq('md')} {
      ${calcMenuOffset('md', 32)}
    }
  }
`;

/* block interaction / darken body */
export const DimContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: ${depth.MENU_COVER};
  opacity: 0.5;
  overflow-x: hidden;
`;

/* block interaction over header */
export const MenuActiveBlockInteraction = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  z-index: ${depth.MENU_BLOCK};
  /* make menu button can't clickable while user dropdown is opening */
  &.userDropdownActive {
    z-index: ${depth.MENU_BUTTON + 1};
  }
`;
