import React from 'react';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { withEditMode, withEditModeNotice } from '_containers/BaseComponent';
import { canUseDOM } from 'exenv';
import StyledContentRichText from './StyledContentRichText';

/**
 * ContentRichText component.
 * Connected component for the display of the Rich Text Content.
 */

type RichTextProps = {
  field?: any;
  fields: {
    body: any;
  };
  history?: any;
  className?: string;
  editMode?: boolean;
};

const handleClick = (history, e) => {
  if (!canUseDOM) {
    return;
  }
  const { target } = e;

  if (target.tagName.toLowerCase() === 'a') {
    // eslint-disable-next-line no-useless-escape
    var match = target.href.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
    var isExternal =
      (typeof match[1] === 'string' &&
        match[1].length > 0 &&
        match[1].toLowerCase() !== window.location.protocol) ||
      (typeof match[2] === 'string' &&
        match[2].length > 0 &&
        match[2].replace(
          new RegExp(':(' + { 'http:': 80, 'https:': 443 }[window.location.protocol] + ')?$'),
          ''
        ) !== window.location.host);
    var isDownload = e.target.pathname.startsWith('/-/media/');

    var isSigninLink = e.target.pathname.startsWith('/oidc/signin');

    // Allow links to skip React Router's client side routing
    // Example: <a href="/oidc/signin" data-reload-document="true">Login</a>
    var skipClientSideRouting = e.target?.getAttribute("data-reload-document") === "true";

    if (target.pathname !== '/' && !isExternal && !isDownload && !isSigninLink && !skipClientSideRouting) {
      e.preventDefault();

      if (target.hash) {
        // handle anchor on same page
        try {
          const currentPath = window.location.pathname;
          const currentHash = window.location.hash;

          if (
            target.pathname.toLowerCase() === currentPath.toLowerCase() &&
            target.hash.toLowerCase() === currentHash.toLowerCase()
          ) {
            // if link points to anchor on this page, scroll to it
            const id = target.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth'
              });
              // exit without updating history
              return;
            }
          }
        } catch (err) {}
      }

      history?.push(target.pathname + (target.hash ? target.hash : ''));
    }
  }
};

const MissingDataSource = compose(withEditModeNotice)(`div`);

export const ContentRichText: React.FC<RichTextProps> = ({
  field,
  fields,
  history,
  className = '',
  editMode
}) => {
  const richTextField = typeof field === 'undefined' ? fields?.body : field;

  if (!richTextField) {
    if (editMode) {
      return (
        <MissingDataSource
          noticeEnabled={true}
          noticeMessage={`ContentRichText has no data source`}
        />
      );
    } else {
      return null;
    }
  }
  return (
    <StyledContentRichText
      onClick={handleClick.bind(null, history)}
      className={className}
      field={richTextField}
    />
  );
};

export default compose(withRouter, withEditMode)(ContentRichText);
