import { useState, useEffect } from 'react';
import { canUseDOM } from 'exenv';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

// data layer is not immediately available
// call callback(dataLayer) when it is
const getDataLayer = (callback: (dataLayer: any) => void, attempts = 0): void => {
  if (!canUseDOM) {
    callback(null);
    return;
  }

  const { dataLayer } = window;

  if (dataLayer) {
    callback(dataLayer);
    return;
  } else {
    requestAnimationFrame(() => {
      if (attempts < 100) {
        // keep trying
        getDataLayer(callback, attempts + 1);
      } else {
        //give up
        callback(null);
      }
    });
  }
};

export const useDataLayer = () => {
  const [dataLayer, setDataLayer] = useState<any[]>(null);

  useEffect(() => {
    getDataLayer((dataLayer) => setDataLayer(dataLayer));
  }, []);

  return dataLayer;
};

export default useDataLayer;
